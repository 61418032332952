import React from "react";

const Search = ({ ...props }) => {
  return (
    <>
      <input
        type="search"
        name="search"
        placeholder="Search"
        disabled
        className="w-full py-3 px-4 outline-none border-[1px] rounded-[5px]"
        {...props}
      />
    </>
  );
};

export default Search;
