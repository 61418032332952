import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import EventPage from "../pages/eventPage";
import ViewChapterPage from "../pages/viewChapters";
import CoreTeamPage from "../pages/coreTeam";
import DirectorsPage from "../pages/directors";
import GalleryPage from "../pages/gallery";
import AboutPage from "../pages/about";
import CampusLeadsPage from "../pages/campusLeads";
import ContactPage from "../pages/contact";
import ScrollToTop from "../utils/scrollToTop";
import BlogPage from "../pages/singleBlog";
import Blogs from "../pages/blog";
import StateLeadsPage from "../pages/stateLeads";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/events" element={<EventPage />} />
        <Route path="/view-chapters" element={<ViewChapterPage />} />
        <Route path="/core-team" element={<CoreTeamPage />} />
        <Route path="/directors" element={<DirectorsPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/lead-a-chapter" element={<StateLeadsPage />} />
        <Route path="/campus-leads" element={<CampusLeadsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blog/:id" element={<BlogPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
