import React from "react";
import PageLayout from "../components/layout/pageLayout";
import { Link } from "react-router-dom";

const StateLeadsPage = () => {
  return (
    <PageLayout
      title="State/Campus Leads - ATC Africa"
      metaDescription="Check the list of ATC Africa campus leads for each university"
      href="https://atcafrica.com.ng"
    >
      <section className="flex flex-col items-center justify-center w-ful">
        <div className="bg-state-lead bg-center bg-no-repeat bg-cover bg-primaryColor bg-blend-soft-light h-[500px] w-full flex justify-center items-center px-6 lg:px-10">
          <h1 className="text-white text-center font-[700] text-[50px] md:text-[70px]">
            Call for State Leads
          </h1>
        </div>
      </section>
      <div className="w-full max-w-[900px] mx-auto my-8 flex flex-col gap-y-4 px-6 lg:px-10">
        <p>
          ATC Africa is committed to empowering individuals across Africa to
          build successful careers in community development.
        </p>
        <p>
          We believe that fostering innovation and growth requires strong
          leadership and capacity-building, not just technical skills.
        </p>
        <p>
          That's why we're seeking State Leads to establish chapters in their
          respective regions. If you have the skills and passion to lead a
          community-focused initiative, we encourage you to apply.
        </p>
        <p>
          Event Management: Plan and execute both virtual and in-person events.
        </p>
        <p>
          Chapter Oversight: Manage and coordinate ATC Africa chapters in your
          designated region.
        </p>
        <p>
          For example, as ATC Lagos Lead, you would oversee chapters like ATC
          Unilag.
        </p>
        <p>Call For State Leads</p>

        <Link
          className="bg-primaryColor w-max py-2 px-4 text-white mx-auto rounded-[8px]"
          to="https://bit.ly/3NRVl1G"
        >
          Apply to Lead a Chapter
        </Link>
      </div>
    </PageLayout>
  );
};

export default StateLeadsPage;
