import "./App.css";
import AppRouter from "./router/approuter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const helmetContext = {};
function App() {
  return (
    <HelmetProvider context={helmetContext}>
      <AppRouter />
      <ToastContainer />
    </HelmetProvider>
  );
}

export default App;
