import React, { useEffect, useState } from "react";
import PageLayout from "../components/layout/pageLayout";
import { Link, NavLink, useParams } from "react-router-dom";
import Search from "../components/Search";

const BlogPage = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setIsLoading] = useState(true);
  const [recent, setRecent] = useState(null);

  useEffect(() => {
    const getBlog = async () => {
      try {
        setIsLoading(true);
        const req = await fetch("/data/blogposts.json");
        const res = await req.json();
        const filterRes = res.filter((data) => data.address === id);
        if (filterRes[0].name) setData(filterRes);
        const arr = [];
        for (let index = 0; index < res.length; index++) {
          if (index == 4) break;
          else {
            arr.push(res[index]);
          }
        }
        const filterArr = arr.filter((data) => data.address !== id);
        setRecent(filterArr);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getBlog();
  }, [id]);
  return (
    <PageLayout
      title={data && `${data[0].name} - ATC Africa`}
      metaDescription={data && `ATC Africa: ${data[0].name}`}
      href="https://atcafrica.com.ng"
      metaImage={data && `${data[0].headlinePoster}`}
      metaUrl={`https:atcafrica.com.ng/blog/${data && data[0].address}`}
    >
      <>
        {loading ? (
          <div className="h-[300px] flex flex-col items-center justify-center">
            <p className="text-[20px] text-gray-700">Loading...</p>
          </div>
        ) : data ? (
          <div className="my-8 w-full max-w-[1440px] px-6 lg:px-10 mx-auto flex flex-col md:flex-row gap-6 lg:gap-12 overflow-visible">
            <div className="hidden md:flex flex-row md:flex-col gap-6 items-center w-full max-w-[50px] sticky top-[128px] h-max">
              <h2 className="text-[12px] font-[500] text-primaryColor">
                SHARE
              </h2>
              <Link to="https://x.com/">
                <img
                  src="/icons/x.svg"
                  alt="social media icons"
                  className="w-[15px]"
                />
              </Link>
              <Link to="https://www.linkedin.com/">
                <img
                  src="/icons/linkedin.svg"
                  alt="social media icons"
                  className="w-[15px]"
                />
              </Link>

              {/* <Link to="https://www.instagram.com/atcafricahq/"></Link> */}
              <Link to="https://web.facebook.com/">
                <img
                  src="/icons/facebook.svg"
                  alt="social media icons"
                  className="w-[15px]"
                />
              </Link>
            </div>
            <div className="w-full max-w-[800px] text-gray-800">
              <div>
                <h2 className="text-[24px] md:text-[24px] text-primaryColor font-[600] capitalize">
                  {data[0].name}
                </h2>
                <div className="mt-2 flex justify-between items-center">
                  <div className="flex items-center gap-2">
                    <img
                      src={data[0].author}
                      alt="author-pic"
                      className="size-[30px]"
                    />
                    <div>
                      <p className="text-[14px]">{data[0].authorName}</p>
                      <p className="text-[14px] text-[#808080]">
                        {data[0].date}
                      </p>
                    </div>
                  </div>
                  <p className="text-[14px] text-[#808080]">
                    {data[0].readTime} minutes read
                  </p>
                </div>
                <p className="mt-4 text-[14px] text-gray-500">
                  {data[0].description}
                </p>
              </div>
              <div className="flex md:hidden flex-row md:flex-col gap-6 items-center w-full my-4">
                <h2 className="text-[12px] font-[500] text-primaryColor">
                  SHARE:
                </h2>
                <Link to="https://x.com/">
                  <img
                    src="/icons/x.svg"
                    alt="social media icons"
                    className="w-[15px]"
                  />
                </Link>
                <Link to="https://www.linkedin.com/">
                  <img
                    src="/icons/linkedin.svg"
                    alt="social media icons"
                    className="w-[15px]"
                  />
                </Link>

                {/* <Link to="https://www.instagram.com/atcafricahq/"></Link> */}
                <Link to="https://web.facebook.com/">
                  <img
                    src="/icons/facebook.svg"
                    alt="social media icons"
                    className="w-[15px]"
                  />
                </Link>
              </div>
              <div className="mt-6 flex flex-col gap-4 leading-7">
                {data[0].headlinePosterType === "video" ? (
                  <iframe
                    src={data[0].headlinePoster}
                    title={data[0].name}
                    className="border-[1px] h-[250px] md:h-[350px] lg:h-[450px] w-full mx-auto object-cover"
                  >
                    Enable Javascript to watch this video
                  </iframe>
                ) : (
                  <img
                    src={data[0].headlinePoster}
                    title={data[0].name}
                    className="border-[1px] h-[250px] md:h-[350px] lg:h-[450px] w-full mx-auto object-cover"
                  />
                )}

                <p dangerouslySetInnerHTML={{ __html: data[0].text1 }}></p>
                {data[0].poster2 !== "" && (
                  <img
                    src={data[0].poster2}
                    title={data[0].name}
                    className="border-[1px] h-[250px] md:h-[350px] lg:h-[450px] w-full mx-auto object-cover"
                  />
                )}
                <p dangerouslySetInnerHTML={{ __html: data[0].text2 }}></p>
                {data[0].poster3 !== "" && (
                  <img
                    src={data[0].poster3}
                    title={data[0].name}
                    className="border-[1px] h-[250px] md:h-[350px] lg:h-[450px] w-full mx-auto object-cover"
                  />
                )}
                <p dangerouslySetInnerHTML={{ __html: data[0].text3 }}></p>
                {data[0].poster4 !== "" && (
                  <img
                    src={data[0].poster4}
                    title={data[0].name}
                    className="border-[1px] h-[250px] md:h-[350px] lg:h-[450px] w-full mx-auto object-cover"
                  />
                )}

                <p dangerouslySetInnerHTML={{ __html: data[0].text4 }}></p>
              </div>
            </div>
            <div className="w-full md:max-w-[300px]">
              <form className="mb-8">
                <Search required />
              </form>
              <h2 className="text-[25px] font-[500] border-gray-500">
                Related
              </h2>
              <div className="flex flex-col gap-2 py-4">
                {recent.map((single, index) => {
                  return (
                    <NavLink
                      key={index}
                      to={`/blog/${single.address}`}
                      className="hover:bg-gray-100 py-4 px-4 flex flex-col gap-2"
                    >
                      <p className="uppercase text-[12px] text-primaryColor">
                        {single.type}
                      </p>
                      <p className="capitalize">{single.name}</p>
                      <p className="text-[14px] text-gray-500">
                        {single.description.slice(0, 105)}...
                      </p>
                    </NavLink>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div className="h-[300px] flex flex-col items-center justify-center">
            <p className=" text-gray-700">Post not found..!</p>
          </div>
        )}
      </>
    </PageLayout>
  );
};

export default BlogPage;
