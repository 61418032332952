import React, { useEffect, useState } from "react";
import PageLayout from "../components/layout/pageLayout";
import { NavLink, useParams } from "react-router-dom";
import BlogCard from "../components/card/blogCard";
import Search from "../components/Search";

const Blogs = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setIsLoading] = useState(true);
  const [recent, setRecent] = useState(null);
  console.log(id);

  useEffect(() => {
    const getBlog = async () => {
      try {
        setIsLoading(true);
        const req = await fetch("/data/blogposts.json");
        const res = await req.json();
        setData(res);
        const arr = [];
        for (let index = 1; index < res.length; index++) {
          if (index == 4) break;
          else {
            arr.push(res[index]);
          }
        }

        setRecent(arr);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getBlog();
  }, []);
  return (
    <PageLayout
      title={"Blog - ATC Africa"}
      metaDescription={
        "Welcome to ATC Blog. Keep updated with news about ATC campaign worldwide"
      }
      href="https://atcafrica.com.ng"
    >
      <section className="flex flex-col w-full max-w-[1440px] px-6 lg:px-10 mx-auto">
        {loading ? (
          <div className="h-[300px] flex flex-col items-center justify-center">
            <p className="text-[20px] text-gray-700">Loading...</p>
          </div>
        ) : data ? (
          <div className="w-full max-w-[1200px] mx-auto py-8">
            <div className="text-primaryColor py-4 rounded-[5px]">
              <h1 className="uppercase text-[20px] font-[600] py-1">
                Welcome to ATC Africa Blog
              </h1>
              <p className="mb-4">
                Discover the latest news happening in our community and in the
                tech world
              </p>
            </div>
            <div className="flex flex-col md:flex-row justify-between gap-12">
              <NavLink
                to={`/blog/${data[0].address}`}
                className="self-start w-full rounded-[5px] shadow-[0px_9px_20px_0px_#00000029]"
              >
                <img
                  src={data[0].cardImage}
                  alt=""
                  className="rounded-t-[5px] object-cover h-[450px] w-full"
                />

                <div className="flex-grow px-4 py-6 flex justify-between flex-col gap-4">
                  <div className="flex flex-col gap-1">
                    <p
                      className={`uppercase text-[12px] text-[${data[0].typeColor}] bg-[${data[0].bgTypeColor}] font-[500] w-max px-[12px] py-[2px] rounded-[4px]`}
                    >
                      {data[0].type}
                    </p>
                    <p className="flex-grow text-[25px] font-[600] text-primaryColor capitalize">
                      {data[0].name}
                    </p>
                    <p className="text-[14px] text-gray-500">
                      {data[0].description}
                    </p>
                  </div>
                </div>
              </NavLink>
              <div className="w-full md:max-w-[300px]">
                <form className="hidden md:block mb-8">
                  <Search />
                </form>
                <h2 className="text-[25px] font-[500] border-gray-500">
                  Latest
                </h2>
                <div className="flex flex-col gap-2 py-4">
                  {recent.map((single, index) => {
                    return (
                      <NavLink
                        key={index}
                        to={`/blog/${single.address}`}
                        className="hover:bg-gray-100 py-4 px-4 flex flex-col gap-2"
                      >
                        <p className="uppercase text-[12px] text-primaryColor">
                          {single.type}
                        </p>
                        <p className="capitalize">{single.name}</p>
                        <p className="text-[14px] text-gray-500">
                          {single.description.slice(0, 80)}...
                        </p>
                      </NavLink>
                    );
                  })}
                </div>
                <form className="md:hidden flex flex-col gap-6">
                  <h2 className="text-[25px] font-[500] border-gray-500 ">
                    Search
                  </h2>
                  <Search />
                </form>
              </div>
            </div>
            <div className="my-12">
              <h2 className="text-[25px] font-[500] border-gray-500 pb-2">
                Must Read
              </h2>
              <div className="flex flex-wrap justify-center sm:justify-between gap-x-8 gap-y-12 my-4">
                {recent.map((single, index) => {
                  return (
                    <BlogCard
                      key={index}
                      blogAddress={`/blog/${single.address}`}
                      cardImage={single.cardImage}
                      type={single.type}
                      headline={single.name}
                      text={`${single.description.slice(0, 130)}...`}
                      author={single.author}
                      authorName={single.authorName}
                      date={single.date}
                      readTime={`${single.readTime} mins read`}
                      bgTypeColor={`bg-[${single.bgTypeColor}]`}
                      typeColor={`text-[${single.typeColor}]`}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <>
            <p>Error retrieving data</p>
          </>
        )}
      </section>
    </PageLayout>
  );
};

export default Blogs;
