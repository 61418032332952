import React from "react";
import Header from "../common/header";
import Footer from "../common/footer";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

const PageLayout = ({
  title,
  metaDescription,
  href,
  metaImage,
  metaUrl,
  children,
}) => {
  const { param } = useParams();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content="https://atcafrica.com.ng/atc.svg" />
        <meta property="og:url" content={`https://atcafrica.com.ng/${param}`} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="https://atcafrica.com.ng" />
      </Helmet>
      <div className="font-sen">
        <Header />
        <main className="overflow-clip">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default PageLayout;
